import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import SimpleRadioGroup from '../../../components/RadioGroup';
import SimpleCheckboxGroup from '../../../components/Checkboxes';
import { useEffect, useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import CountrySelector from '../CountrySelector';
import { BlockRule } from '../types';
import { showMessage } from '../../../components/utils/showMessage';

type Props = {
  blockRules?: BlockRule;
  onSubmit: (rules: BlockRule) => any;
  isOfferLevel?: boolean;
};

const initialValue: BlockRule = {
  name: '',
  type: 'all',
  blockAnon: true,
  blockDataCenter: true,
  blockBadIps: [],
  blockLocations: {
    type: 'blacklist',
    locations: [],
  },
  globalFallback: '',
  ipThrottle: {
    ipThrottling: false,
    window: 1440,
    maxRequests: 1,
    blacklistFor: 1440,
  },
  blockByDetector: false,
  priority: 1,
  passback: false,
};

const TrafficBlockForm = ({ blockRules: initialBlock, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    window?: string;
    maxRequests?: string;
    blacklistFor?: string;
  }>({});

  const [blockRules, setBlockRules] = useState<BlockRule>(initialValue);

  useEffect(() => {
    setBlockRules({
      ...initialValue,
      ...initialBlock,
      ipThrottle: {
        ipThrottling: initialBlock?.ipThrottle?.ipThrottling ?? false,
        window: initialBlock?.ipThrottle?.window ?? 1440,
        maxRequests: initialBlock?.ipThrottle?.maxRequests ?? 1,
        blacklistFor: initialBlock?.ipThrottle?.blacklistFor ?? 1440,
      },
    });
  }, [initialBlock]);

  const validateIpThrottle = () => {
    const newErrors: any = {};
    if (blockRules.ipThrottle.ipThrottling) {
      if (
        blockRules.ipThrottle.window === undefined ||
        blockRules.ipThrottle.window === null ||
        isNaN(blockRules.ipThrottle.window)
      ) {
        newErrors.window = 'Window is required and must be a number';
      }
      if (
        blockRules.ipThrottle.maxRequests === undefined ||
        blockRules.ipThrottle.maxRequests === null ||
        isNaN(blockRules.ipThrottle.maxRequests)
      ) {
        newErrors.maxRequests =
          'Max Requests for Window is required and must be a number';
      }
      if (
        blockRules.ipThrottle.blacklistFor === undefined ||
        blockRules.ipThrottle.blacklistFor === null ||
        isNaN(blockRules.ipThrottle.blacklistFor)
      ) {
        newErrors.blacklistFor =
          'Blacklist for is required and must be a number';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateIpThrottle()) {
      return;
    }
    setLoading(true);
    try {
      await onSubmit(blockRules);
      showMessage({
        message: 'Rules updated successfully!',
        severity: 'success',
      });
    } catch (error: any) {
      console.error('Error submitting traffic source rules', error);
      showMessage({
        message: error.message || 'Failed to update rules.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (
    key: string,
    newValue: any,
    type: 'none' | 'booleanString' = 'none'
  ) => {
    const formatMap = {
      booleanString: (v: 'true' | 'false') => v === 'true',
      none: (v: any) => v,
    };

    const formatter = formatMap[type] ?? formatMap.none;
    const finalValue = formatter(newValue);

    setBlockRules({ ...blockRules, [key]: finalValue });
  };

  const handleIpThrottleToggle = () => {
    setBlockRules({
      ...blockRules,
      ipThrottle: {
        ...blockRules.ipThrottle,
        ipThrottling: !blockRules.ipThrottle.ipThrottling,
      },
    });
  };

  const handleIpThrottleInputChange = (key: string, value: any) => {
    setBlockRules({
      ...blockRules,
      ipThrottle: {
        ...blockRules.ipThrottle,
        [key]: value,
      },
    });

    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: undefined }));
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} mb={2}>
        <TextField
          label="Name"
          value={blockRules.name}
          onChange={(v) => onChange('name', v.target.value)}
        ></TextField>
        <TextField
          label="Priority"
          type="number"
          value={blockRules.priority}
          onChange={(v) => onChange('priority', +v.target.value)}
          helperText="A rule with higher value is evaluated first before a rule with a lower value"
          inputProps={{
            onWheel: (e) => {
              e.currentTarget.blur();
            },
          }}
        ></TextField>

        <TextField
          label="Type"
          select
          value={blockRules.type}
          onChange={(v) => onChange('type', v.target.value)}
        >
          <MenuItem value="all">Mobile/Desktop</MenuItem>
          <MenuItem value="mobile">Mobile only</MenuItem>
          <MenuItem value="desktop">Desktop only</MenuItem>
        </TextField>

        <TextField
          label="Fallback link"
          value={blockRules.globalFallback}
          onChange={(v) => onChange('globalFallback', v.target.value)}
        ></TextField>

        <TextField
          label="Passback"
          select
          value={blockRules.passback}
          onChange={(v) => onChange('passback', v.target.value === 'true')}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </TextField>

        <Box display="flex" gap={4} flexDirection="row">
          <SimpleRadioGroup
            fields={[
              { value: true, label: 'Block' },
              { value: false, label: 'Allow' },
            ]}
            label="Block anonymous"
            onChange={(v: any) => onChange('blockAnon', v, 'booleanString')}
            value={blockRules.blockAnon}
            helperText="Tor and proxies identified by AWS"
          ></SimpleRadioGroup>

          <SimpleRadioGroup
            fields={[
              { value: true, label: 'Block' },
              { value: false, label: 'Allow' },
            ]}
            label="Block Data center & VPNs"
            onChange={(v: any) =>
              onChange('blockDataCenter', v, 'booleanString')
            }
            value={blockRules.blockDataCenter}
          ></SimpleRadioGroup>

          <SimpleCheckboxGroup
            fields={[
              { value: 'gen', label: 'General Malicious IPs' },
              { value: 'ddos', label: 'DDOS' },
              { value: 'recon', label: 'Reconnaissance/Crawlers' },
            ]}
            label="Block Malicious IP"
            onChange={(v: any) => onChange('blockBadIps', v)}
            value={blockRules.blockBadIps}
          ></SimpleCheckboxGroup>

          <SimpleRadioGroup
            fields={[
              { value: true, label: 'Block' },
              { value: false, label: 'Allow' },
            ]}
            label="Block Detected Extensions"
            onChange={(v: any) =>
              onChange('blockByDetector', v, 'booleanString')
            }
            value={blockRules.blockByDetector}
            helperText="Block unwanted extensions"
          ></SimpleRadioGroup>

          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Checkbox
                  checked={blockRules.ipThrottle?.ipThrottling ?? false}
                  onChange={handleIpThrottleToggle}
                />
              }
              label="Enable IP Throttling"
            />

            {blockRules.ipThrottle?.ipThrottling && (
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  size="small"
                  label="Window (minutes)"
                  type="number"
                  value={blockRules.ipThrottle?.window ?? 1440}
                  onChange={(e) =>
                    handleIpThrottleInputChange(
                      'window',
                      Math.max(0, parseInt(e.target.value))
                    )
                  }
                  inputProps={{
                    min: 0,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur();
                    },
                  }}
                  required
                  error={!!errors.window}
                  helperText={errors.window}
                />
                <TextField
                  size="small"
                  label="Max Requests for Window"
                  type="number"
                  value={blockRules.ipThrottle?.maxRequests ?? 1}
                  onChange={(e) =>
                    handleIpThrottleInputChange(
                      'maxRequests',
                      Math.max(1, parseInt(e.target.value))
                    )
                  }
                  inputProps={{
                    min: 1,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur();
                    },
                  }}
                  required
                  error={!!errors.maxRequests}
                  helperText={errors.maxRequests}
                />
                <TextField
                  label="Blacklist for (minutes)"
                  type="number"
                  size="small"
                  value={blockRules.ipThrottle?.blacklistFor ?? 1440}
                  onChange={(e) =>
                    handleIpThrottleInputChange(
                      'blacklistFor',
                      Math.max(-1, parseInt(e.target.value))
                    )
                  }
                  inputProps={{
                    min: 0,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur();
                    },
                  }}
                  required
                  error={!!errors.blacklistFor}
                  helperText={errors.blacklistFor}
                />
              </Box>
            )}
          </Box>
        </Box>
        <CountrySelector
          value={blockRules.blockLocations}
          onChange={(v: any) => onChange('blockLocations', v)}
        />
      </Box>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </>
  );
};

export default TrafficBlockForm;
