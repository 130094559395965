import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Paper, Checkbox, Box } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CellFilter from './FilterCell';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

interface Column {
  field: string;
  name: string;
  filter?: boolean;
  flex?: number;
}

interface DynamicTableProps {
  columns: Column[];
  originalData: any[];
  filteredData: any[];
  renderCell: (field: string, data: any) => JSX.Element;
  selectedRows: string[];
  setSelectedRows: (rows: string[]) => void;
  onAdd?: () => void;
  onEdit?: (row: any) => void;
  onExport?: () => void;
  filters: any;
  setFilters: (filters: any) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  columns,
  originalData,
  filteredData,
  renderCell,
  selectedRows,
  setSelectedRows,
  onAdd,
  onEdit,
  onExport,
  filters,
  setFilters,
}) => {
  const [sorting, setSorting] = useState<{
    column: string;
    order: 'asc' | 'desc';
  }>({
    column: '',
    order: 'asc',
  });

  const handleSortClick = (column: string) => {
    if (column === '_id' || column === 'actions') {
      return;
    }
    setSorting((prevSorting) => ({
      column,
      order:
        prevSorting.column === column && prevSorting.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const handleRowSelection = (event: any) => {
    setSelectedRows(event.api.getSelectedRows().map((row: any) => row._id));
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      sortable: false,
      filter: false,
    },
    ...columns.map((column) => ({
      headerName: column.name,
      field: column.field,
      sortable: true,
      filter: column.filter ? 'agTextColumnFilter' : false,
      cellRenderer: (params: any) => renderCell(column.field, params.data),
      flex: column.flex,
    })),
  ];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        className="ag-theme-alpine"
        style={{ height: '100vh', width: '100%' }}
      >
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={{ sortable: true, filter: true, floatingFilter: true }}
          rowSelection="multiple"
          onSelectionChanged={handleRowSelection}
          pagination={true}
          paginationPageSize={20}
          className="ag-theme-alpine"
        />
      </Box>
    </Paper>
  );
};

export default DynamicTable;
