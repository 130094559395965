import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import { Box, CircularProgress, Paper, Chip } from '@mui/material';
import useClicks from '../../useClicks';
import moment from 'moment';
import RequestTableToolbar from '../Toolbar';
import { Range } from 'react-date-range';

function RequestLogs() {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const client = useClicks();

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await client
        .getClicks()
        .call(
          moment(dateRange.startDate).format('YYYY-MM-DD'),
          moment(dateRange.endDate).format('YYYY-MM-DD')
        );

      setData(res.clicks.result);
      setTotalRowCount(res.clicks.totalCount);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  const handleDateFilterSubmit = (newDateRange: Range) => {
    setDateRange(newDateRange);
  };

  const getBlockingRuleChips = (blockingRules: any[]) => {
    const chips = blockingRules
      ?.map((rule, index) => {
        const blockReasons = Object.keys(rule.rule || {}).filter(
          (key) => rule.rule[key]
        );
        return blockReasons.map((reason, reasonIndex) => (
          <Chip
            key={`${index}-${reasonIndex}`}
            label={reason}
            color="error"
            sx={{
              height: '24px',
              fontSize: '0.75rem',
              lineHeight: '20px',
              margin: 'auto',
            }}
          />
        ));
      })
      .flat();

    if (chips.length === 0) {
      return (
        <Chip
          label="Not Blocked"
          color="success"
          sx={{
            height: '24px',
            fontSize: '0.75rem',
            lineHeight: '20px',
            margin: 'auto',
          }}
        />
      );
    }

    return chips;
  };

  const columns: ColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      flex: 1,
      valueFormatter: (params: any) => {
        return (
          moment(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss') + 'UTC'
        );
      },
    },
    // { field: '_id', headerName: 'ID', flex: 1 },
    { field: 'request.ip', headerName: 'IP', flex: 1 },
    { field: 'offerDetails.name', headerName: 'Offer', flex: 1 },
    { field: 'trafficDetails.name', headerName: 'Traffic Source', flex: 1 },
    { field: 'domainDetails.domainName', headerName: 'Domain', flex: 1 },
    {
      field: 'blockingRules',
      headerName: 'Blocked Reason',
      flex: 1,
      cellStyle: { display: 'flex', alignItems: 'center', padding: '0' },
      valueGetter: (params: any) => {
        const blockingReasons =
          params.data.blockingRules
            ?.map((rule: any) => {
              return Object.keys(rule.rule || {}).filter(
                (key) => rule.rule[key]
              );
            })
            .flat() || [];
        return blockingReasons.join(', ');
      },
      cellRenderer: (params: any) => (
        <Box>{getBlockingRuleChips(params.data.blockingRules)}</Box>
      ),
    },
  ];

  const getRowHeight = (params: any) => {
    const blockingRules = params.data.blockingRules || [];
    const blockReasonsCount = blockingRules.reduce(
      (count: number, rule: any) => {
        return (
          count +
          Object.keys(rule.rule || {}).filter((key) => rule.rule[key]).length
        );
      },
      0
    );
    return blockReasonsCount > 1 ? 50 + (blockReasonsCount - 1) * 24 : 50;
  };

  return (
    <Box className="ag-theme-alpine" style={{ height: '100vh', width: '100%' }}>
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Chip
          variant="outlined"
          color="primary"
          label={`Starts From: ${moment(dateRange.startDate).format('YYYY-MM-DD')}`}
        ></Chip>
        <Chip
          variant="outlined"
          color="primary"
          label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
        ></Chip>
      </Box>
      <RequestTableToolbar
        onDateFilterSubmit={handleDateFilterSubmit}
        initialDateRange={dateRange}
      />
      <Paper sx={{ p: 2, height: '100%' }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            enableCellTextSelection={true}
            pagination={true}
            paginationPageSize={20}
            paginationPageSizeSelector={[10, 20, 30, 50, 100, 200]}
            getRowHeight={getRowHeight}
          />
        )}
      </Paper>
    </Box>
  );
}

export default RequestLogs;
