import React, { useState } from 'react';
import { Range } from 'react-date-range';
import { Box, Button, IconButton, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DateFilter from '../DateFilter';
import GroupingDialog from '../Grouping';

type Props = {
  onDateFilterSubmit: (range: Range) => void;
  initialDateRange: Range;
  onGroupByChange: (groupBy: string[]) => void;
  initialGroupBy: string[];
};

const RequestTableToolbarWithGrouping: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
  onGroupByChange,
  initialGroupBy,
}) => {
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [openFilter, setOpenFilter] = useState(false);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);

  const handleDatePickerSubmit = (newRange: Range) => {
    setDateRange(newRange);
    onDateFilterSubmit(newRange);
    setOpenFilter(false);
  };

  return (
    <Box display="column" alignItems="center" pb={2} bgcolor="background.paper">
      <Button
        color="primary"
        variant="outlined"
        startIcon={<FilterAltIcon />}
        onClick={() => setOpenFilter(true)}
        size="small"
        sx={{mr: 1}}
      >
        Date Filter
      </Button>

      <Button
        color="primary"
        variant="outlined"
        onClick={() => setOpenGroupDialog(true)}
        size="small"
      >
        Group By
      </Button>
        <DateFilter
          open={openFilter}
          handleClose={() => setOpenFilter(false)}
          onSubmit={handleDatePickerSubmit}
          initialData={dateRange}
        />

        <GroupingDialog
          open={openGroupDialog}
          onClose={() => setOpenGroupDialog(false)}
          onApply={onGroupByChange}
          initialGroupBy={initialGroupBy}
        />
    </Box>
  );
};

export default RequestTableToolbarWithGrouping;
