import React from 'react';
import RequestTable from './RequestTable/index';
import RequestSummary from './RequestTable/RequestSummary';
import RequestLogs from './RequestTable/RequestLogs';

const index = () => {
  return (
    <>
      <RequestLogs />
      <RequestSummary />
    </>
  );
};

export default index;
