import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TrafficBlockForm from '../../pages/traffic/TrafficBlockForm';
import RuleTable from '../RuleTable';
import { BlockRule } from '../../pages/traffic/types';
import { nanoid } from 'nanoid';

type Props = {
  onDelete: (newRules: BlockRule[]) => any;
  onSubmit: (newRule: BlockRule[]) => any;
  parentRules: BlockRule[];
  onAddNewRule: (newRule: BlockRule) => any;
};

const RuleForm = ({ onDelete, onSubmit, parentRules, onAddNewRule }: Props) => {
  const [ruleLoading, setRuleLoading] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState('');
  const [blockRules, setBlockRules] = useState<BlockRule[]>([]);

  const init = () => {
    const flatRules = [parentRules].flat();
    const newRules = flatRules.map((item) => {
      return {
        ...item,
        id: item.id ?? nanoid(),
        type: item.type ?? 'all',
      };
    });

    setBlockRules(newRules);
  };

  const selectRule = (rule: BlockRule) => {
    if (rule) setSelectedRuleId(rule.id!);
  };

  const handleDelete = async (newRules: BlockRule[]) => {
    setRuleLoading(true);
    await onDelete(newRules);
    setRuleLoading(false);
  };

  const handleEdit = async (newRule: BlockRule) => {
    setRuleLoading(true);

    const newRules = blockRules.map((item) => {
      if (item.id !== newRule.id) return item;

      return newRule;
    });

    await onSubmit(newRules);

    setRuleLoading(false);
  };

  const handleAddRule = async () => {
    const emptyRule: BlockRule = {
      id: nanoid(),
      type: 'all',
      name: '',
      blockAnon: false,
      blockDataCenter: false,
      blockByDetector: false,
      blockBadIps: [],
      blockLocations: {
        locations: [],
      },
      globalFallback: '',
      ipThrottle: {
        ipThrottling: false,
        window: 1440,
        maxRequests: 1,
        blacklistFor: 1440,
      },
      priority: 1,
      passback: false
    };

    setRuleLoading(true);
    await onAddNewRule(emptyRule);
    setRuleLoading(false);
  };

  useEffect(() => {
    init();
  }, [parentRules]);

  const selectedRule = blockRules.find((item) => item.id === selectedRuleId);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Rules
            <Typography variant="body1" color="gray">
              Select a rule to start editing it
            </Typography>
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" onClick={handleAddRule}>
            Add empty rule
          </Button>
        </Box>
      </Box>

      <RuleTable
        blockRules={blockRules}
        tableLoading={ruleLoading}
        onDelete={handleDelete}
        onSelectRule={selectRule}
      />
      {selectedRule && (
        <>
          <Typography variant="h6" sx={{ my: 3 }}>
            Rule settings
            <Typography variant="body1">
              &#40;Editing {selectedRule.name}&#41;
            </Typography>
          </Typography>
          <TrafficBlockForm blockRules={selectedRule} onSubmit={handleEdit} />
        </>
      )}
    </>
  );
};

export default RuleForm;
