import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';

import NotFoundPage from './pages/404';
import TrafficSourcesPage from './pages/traffic';
import SingleTrafficPage from './pages/traffic/SingleTraffic';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import OfferPage from './pages/offer';
import MerchantsPage from './pages/merchants';
import ProductsPage from './pages/products';

import GuardedRoute from './components/GuardedRoute';
import AuthProvider from './context/authContext';
import DomainPage from './pages/domains';
import RedirectPage from './pages/redirect';
import FacebookRedirectPage from './pages/facebookRedirect';
import PostbackPage from './pages/postback';
import LogsPage from './pages/logs';
import AffiliatePage from './pages/affiliates';
import SingleAffiliatePage from './pages/affiliates/SingleAffiliate';
import SingleQualityReportPage from './pages/affiliates/SingleAffiliate/SingleQualityReport';
import MerchantFeeds from './pages/merchants/MerchantFeeds';
import SovrnPage from './pages/sovrn';
import SingleSovrnReportPage from './pages/sovrn/SingleSovrn';
import CustomOfferPage from './pages/offerGroup';
import SingleOfferGroup from './pages/offerGroup/SingleOfferGroup';
import HPReportsPage from './pages/hpReports';
import SingleHpReport from './pages/hpReports/SingleHpReport';
import SingleTrafficBlock from './pages/traffic/TrafficBlock';
import CustomOffer from './pages/offerGroup/CustomOffer';
import OfferGroupReport from './pages/offerGroup/OfferGroupReport';
import SingleCustomReport from './pages/custom-reports/SingleCustomReport';
import OfferGroupSettings from './pages/offerGroup/OfferGroupSettings';
import OfferGroupClicks from './pages/offerGroup/OfferGroupClicks';
import IronDomePage from './pages/ironDome';
import RequestPage from './pages/request';
import PublishersPage from './pages/Publishers';
import CronJobsSovrn from './pages/cron-jobs-sovrn';
import CampaignPage from './pages/campaigns';
import CampaignOfferPage from './pages/campaigns/Offers/OfferTable';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/r/:id',
    element: <RedirectPage />,
  },
  {
    path: '/fb',
    element: <FacebookRedirectPage />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <GuardedRoute>
            <Dashboard />
          </GuardedRoute>
        ),
      },
      {
        path: 'logs',
        element: (
          <GuardedRoute>
            <LogsPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'postback',
        element: (
          <GuardedRoute>
            <PostbackPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'offers',
        element: (
          <GuardedRoute>
            <OfferPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'merchants',
        element: (
          <GuardedRoute>
            <MerchantsPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'traffic',
        element: (
          <GuardedRoute>
            <TrafficSourcesPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'traffic/:sourceId',
        element: (
          <GuardedRoute>
            <SingleTrafficPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'traffic/:sourceId/block',
        element: (
          <GuardedRoute>
            <SingleTrafficBlock />
          </GuardedRoute>
        ),
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'domains',
        element: (
          <GuardedRoute>
            <DomainPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'publishers',
        element: (
          <GuardedRoute>
            <PublishersPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'affiliates',
        element: (
          <GuardedRoute>
            <AffiliatePage />
          </GuardedRoute>
        ),
      },
      {
        path: 'affiliates/:affiliateId',
        element: (
          <GuardedRoute>
            <SingleAffiliatePage />
          </GuardedRoute>
        ),
      },
      {
        path: 'qualityEntry/:qualityEntryId',
        element: (
          <GuardedRoute>
            <SingleQualityReportPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'merchants/:merchantId/feeds',
        element: (
          <GuardedRoute>
            <MerchantFeeds />
          </GuardedRoute>
        ),
      },
      {
        path: 'products',
        element: (
          <GuardedRoute>
            <ProductsPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'sovrn',
        element: (
          <GuardedRoute>
            <SovrnPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'sovrn/:id',
        element: (
          <GuardedRoute>
            <SingleSovrnReportPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'offer-group',
        element: (
          <GuardedRoute>
            <CustomOfferPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'offer-group/:id',
        element: (
          <GuardedRoute>
            <SingleOfferGroup />
          </GuardedRoute>
        ),
        children: [
          {
            path: '',
            element: <CustomOffer />,
            index: true,
          },
          {
            path: 'reports',
            element: <OfferGroupReport />,
          },
          {
            path: 'settings',
            element: <OfferGroupSettings />,
          },
          {
            path: 'clicks',
            element: <OfferGroupClicks />,
          },
        ],
      },
      {
        path: 'custom-report/:id',
        element: (
          <GuardedRoute>
            <SingleCustomReport />
          </GuardedRoute>
        ),
      },
      {
        path: 'hp-reports',
        element: (
          <GuardedRoute>
            <HPReportsPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'hp-reports/:id',
        element: (
          <GuardedRoute>
            <SingleHpReport />
          </GuardedRoute>
        ),
      },
      {
        path: 'iron-dome',
        element: (
          <GuardedRoute>
            <IronDomePage />
          </GuardedRoute>
        ),
      },
      {
        path: 'request',
        element: (
          <GuardedRoute>
            <RequestPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'cron-jobs',
        element: (
          <GuardedRoute>
            <CronJobsSovrn />
          </GuardedRoute>
        ),
      },
      {
        path: 'campaigns',
        element: (
          <GuardedRoute>
            <CampaignPage />
          </GuardedRoute>
        ),
      },
      {
        path: 'campaigns/:campaignId/offers',
        element: (
          <GuardedRoute>
            <CampaignOfferPage />
          </GuardedRoute>
        ),
      },
      // {
      //   path: 'reporting',
      //   element: (
      //     <GuardedRoute>
      //       <ReportingPage />
      //     </GuardedRoute>
      //   ),
      // },
      {
        path: '/404',
        element: <NotFoundPage />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
