import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Download from '@mui/icons-material/Download';
import { AppRegistration, Close as CloseIcon } from '@mui/icons-material';
import useCustomOffer from '../useCustomOffers';
import ImportCustomOffer from '../ImportCustomOffer';
import { useContext } from 'react';
import { OfferGroupContext } from '../context';
import { showMessage } from '../../../components/utils/showMessage';

type Props = {
  onDownloadTemplate: () => any;
  onImportDone: (dto: any) => any;
  onExport: () => any;
  selectedRows?: any[];
  onBulkEdit: () => any;
};

const SingleOfferGroupToolbar = ({
  onDownloadTemplate,
  onImportDone,
  onExport,
  selectedRows = [],
  onBulkEdit,
}: Props) => {
  const api = useCustomOffer();
  const { offerGroup } = useContext(OfferGroupContext);

  const [importDialogOpen, setImportDialogOpen] = useState(false);

  const handleImportDialogOpen = () => {
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const onSubmitCSV = async (dto: any) => {
    try {
      const result = await api.importCustomOffers().call(offerGroup?._id, dto);
      showMessage({ message: 'Imported successfully!' });
      onImportDone(result);
      handleImportDialogClose();
    } catch (error: any) {
      showMessage({
        message: error.message || 'Something went wrong.',
        severity: 'error',
      });
    }
  };

  return (
    <Box display="flex" gap={2} mb={2}>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={onDownloadTemplate}
      >
        Download Template
      </Button>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleImportDialogOpen}
      >
        Import
      </Button>
      <Button
        color="primary"
        startIcon={<Download />}
        onClick={onExport}
      >
        Export
      </Button>
      {selectedRows.length > 0 && (
        <Button
          color="primary"
          startIcon={<AppRegistration />}
          onClick={onBulkEdit}
        >
          Bulk Edit
        </Button>
      )}
      <Dialog open={importDialogOpen} onClose={handleImportDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Import Custom Offers
          <IconButton
            aria-label="close"
            onClick={handleImportDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ImportCustomOffer
            open={importDialogOpen}
            handleClose={handleImportDialogClose}
            onSubmit={onSubmitCSV}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImportDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SingleOfferGroupToolbar;