import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Tooltip, IconButton, Alert } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { Add, Edit, Delete, Dashboard } from '@mui/icons-material';
import CampaignDialog from '../CampaignDialog';
import useCampaign from '../useCampaign';
import ConfirmModal from '../../../components/ConfirmModal';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { showMessage } from '../../../components/utils/showMessage';

function CampaignTable() {
  const { getCampaigns, addCampaign, editCampaign, deleteCampaign } =
    useCampaign();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = (campaign: any = null) => {
    setSelectedCampaign(campaign);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedCampaign(null);
  };

  const handleSaveCampaign = async (campaignData: any) => {
    try {
      setLoading(true);
      if (selectedCampaign) {
        await editCampaign().call(selectedCampaign._id, campaignData);
        showMessage({
          message: 'Campaign updated successfully!',
          severity: 'success',
        });
      } else {
        await addCampaign().call(campaignData);
        showMessage({
          message: 'Campaign added successfully!',
          severity: 'success',
        });
      }
      handleDialogClose();
      fetchData();
    } catch (error) {
      showMessage({ message: 'Error saving campaign.', severity: 'error' });
      console.error('Error saving campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCampaign = async (id: string) => {
    try {
      setLoading(true);
      await deleteCampaign().call(id);
      showMessage({
        message: 'Campaign deleted successfully!',
        severity: 'success',
      });
      fetchData();
    } catch (error) {
      showMessage({ message: 'Error deleting campaign.', severity: 'error' });
      console.error('Error deleting campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const campaigns = await getCampaigns().call();
      setData(campaigns);
      setTotalRowCount(campaigns.length);
    } catch (error) {
      showMessage({ message: 'Error fetching campaigns.', severity: 'error' });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { headerName: 'ID', field: '_id', flex: 1 },
    { headerName: 'Name', field: 'name', flex: 1 },
    { headerName: 'Entry Domain', field: 'entryDomain', flex: 1 },
    {
      headerName: 'Offers',
      field: 'offers',
      flex: 1,
      filter: false,
      sortable: false,
      cellRenderer: (params: any) => (
        <Tooltip title="View Offers">
          <IconButton
            size="small"
            onClick={() => navigate(`/campaigns/${params.data._id}/offers`)}
          >
            <Dashboard />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      filter: false,
      sortable: false,
      cellRenderer: (params: any) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Edit Campaign">
            <IconButton
              size="small"
              onClick={() => handleDialogOpen(params.data)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <ConfirmModal
            iconButton
            icon={<Delete />}
            title="Delete Campaign"
            onConfirm={() => handleDeleteCampaign(params.data._id)}
          >
            <Alert severity="warning">
              Are you sure you want to delete this campaign? This action cannot
              be undone.
            </Alert>
          </ConfirmModal>
        </Box>
      ),
    },
  ];

  return (
    <Box mt={4}>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display={'flex'} gap={1} alignItems={'center'}>
          {/* <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          />
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          /> */}
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleDialogOpen()}
          >
            Add Campaign
          </Button>
        </Box>
      </Box>
      <Box className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          defaultColDef={{
            sortable: true,
            filter: true,
            floatingFilter: true,
            flex: 1,
          }}
          pagination
          paginationPageSize={paginationModel.pageSize}
          onPaginationChanged={(event) => {
            const currentPage = event.api.paginationGetCurrentPage();
            const pageSize = event.api.paginationGetPageSize();

            if (
              paginationModel.page !== currentPage ||
              paginationModel.pageSize !== pageSize
            ) {
              setPaginationModel({ page: currentPage, pageSize });
              fetchData();
            }
          }}
          onGridReady={(params) => {
            setLoading(false);
          }}
          loadingOverlayComponentParams={{ loading }}
        />
      </Box>
      <CampaignDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={handleSaveCampaign}
        initialCampaign={selectedCampaign}
      />
    </Box>
  );
}

export default CampaignTable;
