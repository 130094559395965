import React, { memo, useContext, useMemo } from 'react';
import DynamicToolbar from '../../../../components/DynamicToolbar';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

import AddOffer from '../../AddOffer';
import EditOffer from '../../EditOffer';
import DeleteOffer from '../../DeleteOffer';
import TestOffer from '../../TestOffer';
import useOffer from '../../useOffer';

import { OfferContext } from '../../context';
import ArchiveOffer from '../../ArchiveOffer';

function OfferToolbar({
  selectedRow,
  rowToArchive,
  onBulkArchiveDone,
  onAddOfferDone,
  onEditOfferDone,
  onDeleteOfferDone,
  onImportOfferDone,
  rowToDelete,
  currentData,
  getTrafficSourceId,
  getRedirectDomainId,
  getEntryDomainId,
  showArchive,
}) {
  const { addOffer, editOffer, deleteOffer, importOffer, bulkArchiveOffer } =
    useOffer();
  const { traffic, domains } = useContext(OfferContext);

  const onAddOffer = async (newOffer, handleClose, resetValues) => {
    const offer = await addOffer().call(newOffer);
    resetValues({
      offerLink: '',
      trafficSourceId: '',
      name: '',
      fallBackUrl: '',
      redirectDomainId: '',
      entryDomainId: '',
    });
    onAddOfferDone(offer);
    handleClose(true);
  };

  const onEditOffer = async (newOffer) => {
    const offer = await editOffer().call(selectedRow._id, newOffer);
    onEditOfferDone(offer);
  };

  const onDeleteOffer = async (id, handleClose) => {
    await deleteOffer().call(id);
    onDeleteOfferDone(id);
    handleClose(true);
  };

  const handleBulkArchive = async (initialData, archive) => {
    const offer = await bulkArchiveOffer().call(initialData, archive);
    onBulkArchiveDone(offer.data.updatedOffers);
  };

  const onDownloadTemplate = () => {
    const templateData = [
      ['Name', 'Offer link', 'Redirect link', 'Entry domain', 'Traffic source'],
    ];

    const csvContent = templateData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'offerTemplate.csv';

    link.click();

    URL.revokeObjectURL(link.href);
  };

  const onImportOffer = async (data) => {
    const headers = data[0];

    const formattedData = [];

    for (let i = 1; i < data.length; i++) {
      const row = data[i];

      const formattedRow = {};
      if (row.length > 1) {
        formattedRow['name'] = row[headers.indexOf('Name')];
        formattedRow['offerLink'] = row[headers.indexOf('Offer link')];
        formattedRow['redirectDomainId'] = getRedirectDomainId(
          row[headers.indexOf('Redirect link')]
        );
        formattedRow['entryDomainId'] = getEntryDomainId(
          row[headers.indexOf('Entry domain')]
        );
        formattedRow['trafficSourceId'] = getTrafficSourceId(
          row[headers.indexOf('Traffic source')]
        );

        formattedData.push(formattedRow);
      }
    }
    const res = await importOffer().call(formattedData);
    onImportOfferDone(res);
    window.alert('CSV data successfully processed.');
  };

  const modalButtons = useMemo(() => {
    const res = [
      {
        name: 'add',
        label: 'Add',
        renderIf: true,
        icon: <AddIcon />,
        render: (open, handleClose) => {
          return (
            <AddOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onAddOffer}
            />
          );
        },
      },
      {
        name: 'edit',
        label: 'Edit',
        renderIf: Boolean(selectedRow),
        icon: <EditIcon />,
        render: (open, handleClose) => {
          return (
            <EditOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onEditOffer}
              initialData={selectedRow}
            />
          );
        },
      },
      {
        name: 'test',
        label: 'Test',
        renderIf: true,
        icon: <BuildIcon />,
        render: (open, handleClose) => {
          return <TestOffer open={open} handleClose={handleClose} />;
        },
      },
      {
        name: 'delete',
        label: 'Delete',
        renderIf: Boolean(rowToDelete.length > 0),
        icon: <DeleteIcon />,
        render: (open, handleClose) => {
          return (
            <DeleteOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onDeleteOffer}
              initialData={rowToDelete}
            />
          );
        },
      },
      {
        name: 'archive',
        label: 'Bulk Archive/Unarchive',
        renderIf: rowToArchive.length > 1,
        icon: <ArchiveIcon />,
        render: (open, handleClose) => {
          return (
            <ArchiveOffer
              open={open}
              handleClose={handleClose}
              onSubmit={handleBulkArchive}
              initialData={rowToArchive}
              showArchive={showArchive}
            />
          );
        },
      },
    ];

    return res;
  }, [selectedRow, rowToDelete]);

  const exportOptions = {
    name: 'Name',
    offerLink: 'Offer link',
    fallBackUrl: {
      label: 'Fallback URL',
    },
    redirectLink: {
      label: 'Redirect link',
    },
    entryDomain: {
      label: 'Entry domain',
    },
    trafficSource: {
      label: 'Traffic source',
    },
    archive: {
      label: 'Archive status',
    },
  };

  return (
    <DynamicToolbar
      modalButtons={modalButtons}
      allowExport
      allowImport
      exportFileName="offerTable.csv"
      rawExportData={currentData}
      exportOptions={exportOptions}
      onImportOffer={onImportOffer}
      onDownloadTemplate={onDownloadTemplate}
    />
  );
}

export default memo(OfferToolbar);
