import { useContext, useEffect, useState } from 'react';
import { OfferContext } from '../context';
import SimpleModal from '../../../components/SimpleModal';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Autocomplete,
  Button,
  IconButton,
  Divider,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function EditOffer({
  open,
  initialData,
  handleClose,
  onSubmit,
}) {
  const [values, setValues] = useState(initialData);
  const offerContext = useContext(OfferContext);

  useEffect(() => {
    setValues(initialData);
  }, [initialData]);

  const { traffic, domains } = offerContext;

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleTrafficSourceChange = (event, newValue) => {
    setValues({
      ...values,
      trafficSourceId: newValue ? newValue._id : '',
    });
  };

  const handleRedirectDomainChange = (event, newValue) => {
    setValues({
      ...values,
      redirectDomainId: newValue ? newValue._id : '',
    });
  };

  const handleEntryDomainChange = (event, newValue) => {
    setValues({
      ...values,
      entryDomainId: newValue ? newValue._id : '',
    });
  };

  const activeDomains = domains.filter((item) => item.active);
  const entryDomains = domains.filter(
    (item) => item.active && item.isEntry === true
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Edit offer
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            setValues({ name: '', offerLink: '', fallBackUrl: '' });
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <TextField
            name="name"
            onChange={onChange}
            label="Name"
            value={values.name}
            fullWidth
          />
          <TextField
            onChange={onChange}
            fullWidth
            label="Offer link"
            name="offerLink"
            value={values.offerLink}
          />
          <TextField
            onChange={onChange}
            fullWidth
            label="Fallback Url"
            value={values.fallBackUrl}
            name="fallBackUrl"
          />
          <TextField
            onChange={onChange}
            fullWidth
            label="Referrer"
            value={values.referrer}
            name="referrer"
          />
          <Autocomplete
            options={traffic}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            value={
              traffic.find((item) => item._id === values.trafficSourceId) ||
              null
            }
            onChange={handleTrafficSourceChange}
            renderInput={(params) => (
              <TextField {...params} label="Traffic Source" fullWidth />
            )}
          />
          <Autocomplete
            options={activeDomains}
            getOptionLabel={(option) => option.domainName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            value={
              activeDomains.find(
                (item) => item._id === values.redirectDomainId
              ) || null
            }
            onChange={handleRedirectDomainChange}
            renderInput={(params) => (
              <TextField {...params} label="Redirect Domain" fullWidth />
            )}
          />
          <Autocomplete
            options={entryDomains}
            getOptionLabel={(option) => option.domainName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            value={
              entryDomains.find((item) => item._id === values.entryDomainId) ||
              null
            }
            onChange={handleEntryDomainChange}
            renderInput={(params) => (
              <TextField {...params} label="Entry Domain" fullWidth />
            )}
          />
        </Box>
      </DialogContent>
      <Divider sx={{ my: 1 }} />
      <DialogActions>
        <Button fullWidth variant="contained" onClick={() => onSubmit(values)}>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
