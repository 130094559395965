import { Box, Grid, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import IronDomeMemberSelector from './IronDomeMemberSelector';
import IronDomeProvider, { IronDomeContext } from './context';
import useOffer from '../offer/useOffer';
import { BlockRule } from '../traffic/types';
import RuleForm from '../../components/RuleForm';

const IronDomeContainer = () => {
  const { selectedMember, setOffers, offers, setSelectedMember } =
    useContext(IronDomeContext);

  const offerClient = useOffer();

  const editRule = async (newRules: BlockRule[]) => {
    const offerId = selectedMember._id;

    const newOffer = { ...selectedMember, blockRules: newRules };

    const api = offerClient.editOffer();
    await api.call(offerId, newOffer);

    const newOffers = offers.map((offer: any) => {
      if (offer._id === offerId) return newOffer;

      return offer;
    });

    setOffers(newOffers);
    setSelectedMember(newOffer);
  };

  const onDelete = async (newRules: BlockRule[]) => {
    const offerId = selectedMember._id;
    const newOffer = { ...selectedMember, blockRules: newRules };

    const api = offerClient.editOffer();
    await api.call(offerId, newOffer);

    const newOffers = offers.map((offer: any) => {
      if (offer._id === offerId) return newOffer;

      return offer;
    });

    setOffers(newOffers);
    setSelectedMember(newOffer);
  };

  const addEmptyRule = async (newRule: BlockRule) => {
    const offerId = selectedMember._id;

    const flatRules = [selectedMember.blockRules].flat();
    flatRules.push(newRule);

    const newOffer = { ...selectedMember, blockRules: flatRules };

    const api = offerClient.editOffer();
    await api.call(offerId, newOffer);

    const newOffers = offers.map((offer: any) => {
      if (offer._id === offerId) return newOffer;

      return offer;
    });

    setOffers(newOffers);
    setSelectedMember(newOffer);
  };

  return (
    <Grid container sx={{ mt: 1 }} spacing={2}>
      <Grid item xs={2}>
        <IronDomeMemberSelector></IronDomeMemberSelector>
      </Grid>
      <Grid item xs={10}>
        {selectedMember ? (
          <Paper>
            <Box p={2}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    {selectedMember.name}
                  </Typography>
                </Box>
              </Box>
              <RuleForm
                onDelete={onDelete}
                onSubmit={editRule}
                parentRules={selectedMember.blockRules}
                onAddNewRule={addEmptyRule}
              />
            </Box>
          </Paper>
        ) : (
          'No selected offer'
        )}
      </Grid>
    </Grid>
  );
};

const IronDomePage = () => {
  return (
    <IronDomeProvider>
      <IronDomeContainer />
    </IronDomeProvider>
  );
};

export default IronDomePage;
