import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Divider,
} from '@mui/material';
import { CustomOffer, CustomOfferGroup } from '../types';
import LoadingButton from '../../../components/LoadingButton';
import { ExpandMore, Close as CloseIcon } from '@mui/icons-material';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';

dayjs.extend(customParseFormat);

type Props = {
  handleClose: () => any;
  row: CustomOffer;
  group: CustomOfferGroup;
  onSubmit: (id: string, newRow: Pick<CustomOffer, 'data' | 'api'>) => any;
  open: boolean;
};

const map = {
  currency: 'number',
  number: 'number',
  string: 'text',
  dropdown: 'select',
} as any;

const EditCustomOffer = ({
  handleClose,
  row,
  group,
  onSubmit,
  open,
}: Props) => {
  const initialApiValues = row.api ?? {
    clicksExcludeDate: [],
    clicksExcludeRange: [],
    hideOffer: false,
  };

  const [values, setValues] = useState(row.data);
  const [loading, setLoading] = useState(false);
  const [showExcludeDate, setShowExcludeDate] = useState(
    initialApiValues.clicksExcludeDate.length > 0
  );
  const [apiValues, setApiValues] =
    useState<CustomOffer['api']>(initialApiValues);

  const hideExcludeDate = () => {
    setShowExcludeDate(false);
    setApiValues({ ...apiValues, clicksExcludeDate: [] });
  };

  const openExcludeDate = () => setShowExcludeDate(true);

  const onHideOffer = () => {
    setApiValues({ ...apiValues, hideOffer: true });
    setValues((prevValues: any) => ({
      ...prevValues,
      status: 'inactive',
    }));
  };

  const onShowOffer = () => {
    setApiValues({ ...apiValues, hideOffer: false });
    setValues((prevValues: any) => ({
      ...prevValues,
      status: 'active',
    }));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await onSubmit(row._id, {
        data: values,
        api: apiValues,
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const onChangeDates = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const l = value.split('\n').filter(Boolean);
    setApiValues({ ...apiValues, clicksExcludeDate: l });
  };

  const { clicksExcludeDate, hideOffer } = apiValues;

  const excludedDatesErr =
    Boolean(clicksExcludeDate) &&
    clicksExcludeDate.some((e) => !dayjs(e, 'YYYY-MM-DD', true).isValid());

  const excludeDateString = apiValues.clicksExcludeDate.join('\n');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Custom Offer
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>API</AccordionSummary>
          <AccordionDetails>
            <Box display="flex" gap={2} flexDirection="column">
              <FormControl>
                <FormLabel>Clicks Visibility</FormLabel>
                <Typography variant="caption">
                  Decide whether to hide or show clicks from this offer
                </Typography>
                <RadioGroup value={showExcludeDate.toString()}>
                  <FormControlLabel
                    value="false"
                    control={<Radio onChange={hideExcludeDate} />}
                    label="Always visible"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio onChange={openExcludeDate} />}
                    label="Exclude dates"
                  />
                </RadioGroup>
              </FormControl>
              {showExcludeDate && (
                <TextField
                  multiline
                  rows={10}
                  fullWidth
                  placeholder="YYYY-MM-DD (1 row per date)"
                  onChange={onChangeDates}
                  error={excludedDatesErr}
                  helperText={excludedDatesErr && 'Invalid dates'}
                  value={excludeDateString}
                />
              )}
              <FormControl>
                <FormLabel>API Visibility</FormLabel>
                <RadioGroup value={hideOffer.toString()}>
                  <FormControlLabel
                    value="false"
                    control={<Radio onChange={onShowOffer} />}
                    label="Show"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio onChange={onHideOffer} />}
                    label="Hide"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Fields
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" gap={2}>
              {group.fields.map((g) => {
                const type = map[g.type] ?? map.string;
                const val = values[g.key] ?? '';
                const options = g.dropdownOptions ?? [];

                const isNumber = type === 'number';
                const isSelect = type === 'select';

                if (g.key === 'status') {
                  return (
                    <TextField
                      select
                      name={g.key}
                      label={g.label}
                      value={val}
                      onChange={onChange}
                      key={g.key}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </TextField>
                  );
                }
                if (g.type === 'boolean') {
                  return (
                    <TextField
                      select
                      name={g.key}
                      label={g.label}
                      value={val}
                      onChange={onChange}
                      key={g.key}
                    >
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </TextField>
                  );
                }
                if (isNumber) {
                  return (
                    <NumericFormat
                      key={g.key}
                      name={g.key}
                      label={g.label}
                      customInput={TextField}
                      value={val}
                      onChange={onChange}
                    />
                  );
                }

                if (isSelect) {
                  return (
                    <TextField
                      select
                      name={g.key}
                      label={g.label}
                      value={val}
                      onChange={onChange}
                      key={g.key}
                    >
                      {options.map((opt) => (
                        <MenuItem key={opt.key} value={opt.key}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }

                return (
                  <TextField
                    key={g.key}
                    type={type}
                    name={g.key}
                    label={g.label}
                    value={val}
                    onChange={onChange}
                  />
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomOffer;
