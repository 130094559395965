import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import { Box, CircularProgress, Paper, Chip } from '@mui/material';
import useClicks from '../../useClicks';
import moment from 'moment';
import RequestTableToolbarWithGrouping from '../ToolbarGrouping';
import { Range } from 'react-date-range';
import { set } from 'lodash';

function RequestSummary() {
  interface SummaryData {
    domainName: string;
    totalClicks: number;
    blockedClicks: number;
    blockedByLocation: number | string;
    blockedByDataCenter: number | string;
    blockedByMaliciousIp: number | string;
    blockedByDetector: number | string;
    blockedByAnon: number | string;
    blockedByGlobalfallback: number | string;
    blockedByIpThrottling: number | string;
  }

  const [data, setData] = useState<SummaryData[]>([]);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [groupBy, setGroupBy] = useState<string[]>([]);
  const [click, setClick] = useState<any>([]);
  const client = useClicks();

  const getClickSummary = async () => {
    setLoading(true);
    try {
      const res = await client
        .getClicksRequest()
        .call(
          moment(dateRange.startDate).format('YYYY-MM-DD'),
          moment(dateRange.endDate).format('YYYY-MM-DD'),
          groupBy,
        );

      const summaryData = res.clicksStats.summary.map((item: any) => ({
        ...item,
        blockedByLocation: item.blockedClicks
          ? ((item.blockedByLocation / item.blockedClicks) * 100).toFixed(2) +
            '%'
          : '0%',
        blockedByDataCenter: item.blockedClicks
          ? ((item.blockedByDataCenter / item.blockedClicks) * 100).toFixed(2) +
            '%'
          : '0%',
        blockedByMaliciousIp: item.blockedClicks
          ? ((item.blockedByMaliciousIp / item.blockedClicks) * 100).toFixed(
              2
            ) + '%'
          : '0%',
        blockedByDetector: item.blockedClicks
          ? ((item.blockedByDetector / item.blockedClicks) * 100).toFixed(2) +
            '%'
          : '0%',
        blockedByAnon: item.blockedClicks
          ? ((item.blockedByAnon / item.blockedClicks) * 100).toFixed(2) + '%'
          : '0%',
        blockedByIpThrottling: item.blockedClicks
          ? ((item.blockedByIpThrottling / item.blockedClicks) * 100).toFixed(
              2
            ) + '%'
          : '0%',
      }));

      const totalSummary = {
        domainName: '',
        totalClicks: res.clicksStats.totalClicks,
        blockedClicks: res.clicksStats.totalBlockedClicks,
        blockedByLocation: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByLocation /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
        blockedByDataCenter: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByDataCenter /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
        blockedByMaliciousIp: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByMaliciousIp /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
        blockedByDetector: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByDetector /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
        blockedByAnon: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByAnon /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
        blockedByIpThrottling: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByIpThrottling /
                res.clicksStats.totalBlockedClicks) *
              100
            ).toFixed(2) + '%'
          : '0%',
      };

      setData([totalSummary, ...summaryData]);
      setTotalRowCount(res.clicksStats.totalClicks);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClickSummary();
  }, [dateRange, groupBy]);

  const handleDateFilterSubmit = (newDateRange: Range) => {
    setDateRange(newDateRange);
  };

  const handleGroupByChange = (newGroupBy: string[]) => {
    setGroupBy(newGroupBy);
  };

  const baseColumns: ColDef[] = [
    {
      field: 'domainName',
      headerName: 'Domain Name',
      sortable: true,
      flex: 1,
      filter: true,
      floatingFilter: true,
    },
  ];

  const headerNameMap: { [key: string]: string } = {
    trafficSource: 'Traffic Source',
    offer: 'Offer',
    date: 'Date',
  };

  const dynamicColumns: ColDef[] = groupBy.map((group) => ({
    field: group,
    headerName: headerNameMap[group] || group,
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  }));

  const additionalColumns: ColDef[] = [
    { field: 'totalClicks', headerName: 'Total Clicks', flex: 1 },
    { field: 'blockedClicks', headerName: 'Blk Clicks', flex: 1 },
    { field: 'blockedByLocation', headerName: 'Blk Loc (%)', flex: 1 },
    { field: 'blockedByDataCenter', headerName: 'Blk D.Center (%)', flex: 1 },
    { field: 'blockedByMaliciousIp', headerName: 'Blk IP (%)', flex: 1 },
    { field: 'blockedByDetector', headerName: 'Blk Ext(%)', flex: 1 },
    { field: 'blockedByAnon', headerName: 'Blk Anon (%)', flex: 1 },
    {
      field: 'blockedByIpThrottling',
      headerName: 'Blk IP Throtle(%)',
      flex: 1,
    },
  ];

  const columns = [...baseColumns, ...dynamicColumns, ...additionalColumns];

  return (
    <Box
      className="ag-theme-alpine"
      style={{ height: '700px', width: '100%' }}
      mt={15}
    >
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Chip
          variant="outlined"
          color="primary"
          label={`Starts From: ${moment(dateRange.startDate).format(
            'YYYY-MM-DD'
          )}`}
        ></Chip>
        <Chip
          variant="outlined"
          color="primary"
          label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
        ></Chip>
      </Box>
      <RequestTableToolbarWithGrouping
        onDateFilterSubmit={handleDateFilterSubmit}
        initialDateRange={dateRange}
        onGroupByChange={handleGroupByChange}
        initialGroupBy={groupBy}
      />
      <Box display={'flex'} gap={2} alignItems={'center'} mb={2}></Box>
      <Paper sx={{ p: 2, height: '100%' }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={20}
            paginationPageSizeSelector={[10, 20, 30, 50, 100, 200]}
          />
        )}
      </Paper>
    </Box>
  );
}

export default RequestSummary;
