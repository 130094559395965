import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Add, Edit, Delete, ArrowBack } from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CampaignOfferForm from '../CampaignOfferForm';
import useCampaignOffer from '../useCampaignOffer';
import ConfirmModal from '../../../../components/ConfirmModal';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { showMessage } from '../../../../components/utils/showMessage';

interface CampaignOffer {
  _id?: string;
  merchantId: string;
  merchantName: string;
  merchantUrl: string;
  offerLink: string;
  clickCap: number;
  commission?: string;
  geo: string;
  device?: string;
}

function CampaignOfferTable() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const navigate = useNavigate();
  const {
    getCampaignOffers,
    addCampaignOffer,
    editCampaignOffer,
    deleteCampaignOffer,
  } = useCampaignOffer();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCampaignOffer, setSelectedCampaignOffer] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<any>(null);

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedCampaignOffer(null);
  };

  const handleSaveCampaignOffer = async (campaignOfferData: CampaignOffer) => {
    try {
      setLoading(true);
      if (selectedCampaignOffer) {
        await editCampaignOffer().call(selectedCampaignOffer._id, {
          ...campaignOfferData,
          campaignId,
        });
        showMessage({
          message: 'Campaign offer updated successfully!',
          severity: 'success',
        });
      } else {
        await addCampaignOffer().call({
          ...campaignOfferData,
          campaignId,
        });
        showMessage({
          message: 'Campaign offer added successfully!',
          severity: 'success',
        });
      }
      handleDialogClose();
      fetchData();
    } catch (error) {
      showMessage({
        message: 'Error saving campaign offer.',
        severity: 'error',
      });
      console.error('Error saving campaign offer:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (campaignOffer: CampaignOffer) => {
    setSelectedCampaignOffer(campaignOffer);
    setIsDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteCampaignOffer().call(id);
      showMessage({
        message: 'Campaign offer deleted successfully!',
        severity: 'success',
      });
      fetchData();
    } catch (error) {
      showMessage({
        message: 'Error deleting campaign offer.',
        severity: 'error',
      });
      console.error('Error deleting campaign offer:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (!campaignId) return;
    setLoading(true);
    try {
      const campaignOffers = await getCampaignOffers().call(campaignId);
      setData(campaignOffers);
    } catch (error) {
      showMessage({
        message: 'Error fetching campaign offers.',
        severity: 'error',
      });
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  const columns: ColDef[] = [
    { headerName: 'Merchant ID', field: 'merchantId', flex: 1 },
    { headerName: 'Merchant Name', field: 'merchantName', flex: 1 },
    { headerName: 'Merchant URL', field: 'merchantUrl', flex: 1 },
    { headerName: 'Offer Link', field: 'offerLink', flex: 1 },
    { headerName: 'Click Cap', field: 'clickCap', flex: 1 },
    {
      headerName: 'Commission',
      field: 'commission',
      flex: 1,
      valueGetter: (params) => params.data?.commission ?? 'N/A',
    },
    { headerName: 'Geo', field: 'geo', flex: 1 },
    {
      headerName: 'Device',
      field: 'device',
      flex: 1,
      valueGetter: (params) => params.data?.device ?? 'N/A',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      filter: false,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Edit Campaign Offer">
            <IconButton size="small" onClick={() => handleEdit(params.data)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <ConfirmModal
            iconButton
            icon={<Delete />}
            title="Delete Campaign Offer"
            onConfirm={() => handleDelete(params.data._id)}
          >
            Are you sure you want to delete this campaign offer? This action
            cannot be undone.
          </ConfirmModal>
        </Box>
      ),
    },
  ];

  return (
    <Box mt={4}>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
        sx={{ mb: 2 }}
      >
        Back
      </Button>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display={'flex'} gap={1} alignItems={'center'}>
          {/* <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          />
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          /> */}
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleDialogOpen}
          >
            Add Campaign Offer
          </Button>
        </Box>
      </Box>
      <Box
        className="ag-theme-alpine"
        style={{ height: '600px', width: '100%' }}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            pagination={true}
            paginationPageSize={25}
            rowSelection="multiple"
            onGridReady={(params) => setGridApi(params.api)}
          />
        )}
      </Box>
      <CampaignOfferForm
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={handleSaveCampaignOffer}
        initialOffer={selectedCampaignOffer}
      />
    </Box>
  );
}

export default CampaignOfferTable;
