import { Search } from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { IronDomeContext } from '../context';
type Props = {};

const IronDomeMemberSelector = (props: Props) => {
  const [searchText, setSearchText] = useState('');

  const { offers, setSelectedMember } = useContext(IronDomeContext);

  const searchOffers = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredOffers = offers.filter((offer) => {
    return offer.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const onClickOffer = (member: any) => {
    setSelectedMember(member);
  };

  return (
    <Paper
      sx={{
        overflowY: 'scroll',
        position: 'relative',
        maxHeight: '100vh',
      }}
    >
      <Box position="sticky" top={0} bgcolor="white" zIndex={99} p={1}>
        <TextField
          InputProps={{ endAdornment: <Search /> }}
          placeholder="Search"
          fullWidth
          size="small"
          onChange={searchOffers}
        />
      </Box>

      <List>
        {filteredOffers.map((item, index) => (
          <ListItemButton key={index} onClick={() => onClickOffer(item)}>
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};

export default IronDomeMemberSelector;
